import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Carrusel from '../components/login/carrusel';
import Anthea from '../assets/Anthea.webp';
import Experiencias from '../components/login/experiencias';
import Promociones from '../components/login/promociones';
import Habitaciones from '../components/login/habitaciones';
import Servicios from './servicios';

export default function HomeView() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/suites-motel-cuenca') {
      const habitacionesElement = document.getElementById('habitaciones');
      if (habitacionesElement) {
        habitacionesElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      <div style={{ padding: "1rem" }}>
        <Carrusel />
        <section id="about" className="about-section">
          <div className="content-wrapper">
            <div className="content-welcome">
              <div className="text-content">
                <h1>Bienvenidos a Anthea Loft</h1>
                <p>
                  Ubicado en un entorno tranquilo, nuestro motel en Cuenca es el lugar perfecto para desconectar y disfrutar de momentos inolvidables. Diseñado para ofrecerte privacidad y confort, cada habitación combina lujo moderno con la calidez de un hogar. Ideal para escapadas románticas y celebraciones especiales.
                </p>
              </div>
            </div>
            <div className="image-content">
              <img src={Anthea} alt="Anthea Loft" />
            </div>
          </div>
        </section>
        <Experiencias />
        <div id="habitaciones">
          <Habitaciones />
        </div>
        <div id="promociones">
          <Promociones />
          <div id="image">
            <div className="overlay-text">
              <h1>Servicios</h1>
            </div>
          </div>
        </div>
        <div id="servicios">
          <Servicios />
        </div>
      </div>
    </>
  );
}
