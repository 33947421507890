import React from 'react';
import ImageWithOverlay from '../components/carddd';
import bar from '../assets/bar.webp';
import ocasiones from '../assets/ocaciones.webp';
import sex from '../assets/sex-xhop.webp';
import tarifas from '../assets/tarifas.webp';
import ImageWithOverlayd from '../components/carddde';

function Servicios() {
  return (
    <div>
      <div className="app">
        <ImageWithOverlay
          imageSrc={bar}
          name="Bar & Restaurant"
          path="/bar-restaurante-motel-cuenca"
        />
        <ImageWithOverlayd
          imageSrc={ocasiones}
          name="Ocasiones Especiales"
          path="/decoraciones-romanticas-motel-cuenca"
        />
        <ImageWithOverlay
          imageSrc={sex}
          name="Sex Shop"
          path="/sexshop-motel-cuenca"
        />
        <ImageWithOverlayd
          imageSrc={tarifas}
          name="Tarifas"
          path="/suites-motel-cuenca-habitaciones"
        />
      </div>
    </div>
  );
}

export default Servicios;
