import React, { useState, useEffect } from 'react';
import imagen1 from '../../assets/Carrousel1.webp';
import imagen2 from '../../assets/Carrousel3.webp';
import imagen3 from '../../assets/Carrousel2.webp';
import imagen4 from '../../assets/Carrousel4.webp';
import imagen5 from '../../assets/Carrousel7.webp';
import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export default function Carrusel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    imagen1,
    imagen2,
    imagen3,
    imagen4,
    imagen5
];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const nextSlide = () => setCurrentSlide((currentSlide + 1) % slides.length);
  const prevSlide = () => setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);

    return (
      <>
      <section id="carousel" className="carousel-section">
        <div className="carousel-container">
          <img src={slides[currentSlide]} alt={`Slide ${currentSlide + 1}`} className="carousel-image" />
          <div className="carousel-text">
          <h2>Lujo y Placer </h2>
        </div>
        <IconButton onClick={prevSlide} className="carousel-button left">
          <ChevronLeft fontSize="large" />
        </IconButton>
        <IconButton onClick={nextSlide} className="carousel-button right">
          <ChevronRight fontSize="large" />
        </IconButton>
        </div>
      </section>

      </>
    )
}
