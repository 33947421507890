import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText, Collapse, List } from '@mui/material';
import { Link } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const NavItem = ({ text, path, icon, child, childrens, onClick }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        if (child) {
            setOpen(!open);
        } else {
            onClick();
        }
    };

    return (
        <>
            <ListItem button component={Link} to={path} onClick={handleClick}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
                {child ? (open ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>
            {child && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {childrens.map((subItem) => (
                            <ListItem
                                key={subItem.name}
                                button
                                component={Link}
                                to={subItem.path}
                                onClick={onClick}
                            >
                                <ListItemText primary={subItem.name} />
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};

export default NavItem;
