import React from 'react';

export default function Cardd({ imageSrc, title, description }) {
  return (
    <div className="card2">
      <div className="card2-content">
        <h2 className="card2-title">{title}</h2>
        <p className="card2-description">{description}</p>
      </div>
      <img src={imageSrc} alt="Card Background" className="card2-image" />
    </div>
  );
}
