import React from 'react';
import '../css/Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LogoFinal from '../assets/logo.png';
import wsp from "../assets/wsp.webp";
import { useNavigate } from 'react-router-dom';

export default function Footer() {
  const navigate = useNavigate();

  const handleNavigate = (path, sectionId) => {
    if (path === "/") {
      navigate(path);
      if (sectionId) {
        setTimeout(() => {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    } else {
      navigate(path);
    }
  };
  return (
    <footer className="footer">
      <div className="footer-column">
        <img className="footer-logo" src={LogoFinal} alt="Logo" height={80} />
        <p>Panamericana Norte KM 14, Cuenca, Ec</p>
        <p>+593 99 594 7244</p>
        <p>anthealoftcue@gmail.com</p>
      </div>

      <div className="footer-column">
        <a href="/" onClick={(e) => { e.preventDefault(); handleNavigate('/'); }}>Inicio</a>
        <a href="#habitaciones" onClick={(e) => { e.preventDefault(); handleNavigate('/', 'habitaciones'); }}>Suites</a>
        <a href="#promociones" onClick={(e) => { e.preventDefault(); handleNavigate('/', 'promociones'); }}>Promociones</a>
        <a href="/contacto" onClick={(e) => { e.preventDefault(); handleNavigate('/contacto-motel-cuenca'); }}>Contactos</a>
      </div>

      <div className="footer-column">
        <a href="https://www.facebook.com/anthealoftec?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
          <FacebookIcon /> Facebook
        </a>
        <a href="https://www.instagram.com/anthealoftmotel?igsh=ZzNpZTNiYjlkNWJn" target="_blank" rel="noopener noreferrer">
          <InstagramIcon /> Instagram
        </a>
        <a href="https://wa.link/2md36j" target="_blank" rel="noopener noreferrer">
          <WhatsAppIcon /> WhatsApp
        </a>
      </div>

      <div className="footer-column footer-center-text">
        <h4>© 2024 Anthea Loft Ecuador, Todos los derechos reservados</h4>
      </div>

      <a href="https://wa.link/2md36j" target="_blank" rel="noopener noreferrer">
        <img src={wsp} className="wsp-btn" alt="WhatsApp" />
      </a>
    </footer>
//     <footer className="footer">
//   <div className="footer-column">
//     <img className="footer-logo" src={LogoFinal} alt="Logo Anthea Loft" height={80} />
//     <p>Panamericana Norte KM 14, Cuenca, Ecuador</p>
//     <p><a href="tel:+593995947244">+593 99 594 7244</a></p>
//     <p><a href="mailto:anthealoftcue@gmail.com">anthealoftcue@gmail.com</a></p>
//   </div>

//   <div className="footer-column">
//     <a href="/" onClick={(e) => { e.preventDefault(); handleNavigate('/'); }}>Inicio - Anthea Loft Cuenca</a>
//     <a href="#habitaciones" onClick={(e) => { e.preventDefault(); handleNavigate('/', 'habitaciones'); }}>Suites de Lujo en Cuenca</a>
//     <a href="#promociones" onClick={(e) => { e.preventDefault(); handleNavigate('/', 'promociones'); }}>Ofertas en Anthea Loft</a>
//     <a href="/contacto" onClick={(e) => { e.preventDefault(); handleNavigate('/contacto'); }}>Contáctanos - Reserva Ya</a>
//   </div>

//   <div className="footer-column">
//     <a href="https://www.facebook.com/anthealoftec?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
//       <FacebookIcon /> Síguenos en Facebook
//     </a>
//     <a href="https://www.instagram.com/anthealoftmotel?igsh=ZzNpZTNiYjlkNWJn" target="_blank" rel="noopener noreferrer">
//       <InstagramIcon /> Síguenos en Instagram
//     </a>
//     <a href="https://wa.link/2md36j" target="_blank" rel="noopener noreferrer">
//       <WhatsAppIcon /> Contáctanos en WhatsApp
//     </a>
//   </div>

//   <div className="footer-column footer-center-text">
//     <h4>© 2024 Anthea Loft Ecuador, Todos los derechos reservados</h4>
//   </div>

//   <a href="https://wa.link/2md36j" target="_blank" rel="noopener noreferrer">
//     <img src={wsp} className="wsp-btn" alt="Contacta vía WhatsApp" />
//   </a>
// </footer>

  );
}
