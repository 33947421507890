import React, { useState, useEffect } from 'react';
import {
  Hotel,
  Star,
  Diamond,
  SentimentSatisfiedAlt,
  ArrowForward
} from '@mui/icons-material';
import '../../css/Hab.css';
import { motion } from 'framer-motion';
import suite from '../../assets/suite.webp';
import estandar from '../../assets/estandar.webp';
import hoja from '../../assets/hoja.webp';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const NaturalRooms = () => {
  const [activeRoom, setActiveRoom] = useState(null);

  const rooms = [
    {
      type: "suite",
      title: "Suite de Lujo",
      description: "Experimenta el máximo confort en nuestra espaciosa suite que te transportara a un lugar de lujo, confort y deseo.",
      images: [
        suite,
        estandar,
        suite
      ],
      icons: [
        { icon: <Hotel />, label: "Hospedaje de lujo" },
        { icon: <Star />, label: "Cinco estrellas" },
        { icon: <Diamond />, label: "Experiencia premium" },
        { icon: <SentimentSatisfiedAlt />, label: "Máximo placer" },
      ],
    },
    {
      type: "standard",
      title: "Suite Estándar",
      description: "Disfruta de una estancia acogedora en nuestra habitación estándar con toques de elegancia.",
      images: [
        estandar,
        suite,
        estandar
      ],
      icons: [
        { icon: <Hotel />, label: "Hospedaje confortable" },
        { icon: <Star />, label: "Calidad garantizada" },
        { icon: <SentimentSatisfiedAlt />, label: "Experiencia placentera" },
      ],
    },
  ];

  const LeafBackground = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    zIndex: -1,
    overflow: 'hidden',
  });

  const navigate = useNavigate();

  const cambiarRuta = () => {
    navigate('/suites-motel-cuenca-habitaciones');
  };

  const Leaf = styled(motion.div)({
    position: 'absolute',
    width: '60px',
    height: '60px',
    opacity: 0.3,
    backgroundSize: 'cover',
  });

  const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 2000);

      return () => clearInterval(interval);
    }, [images]);

    return (
      <div className="image-carousel">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Room view ${index + 1}`}
            className={`room-card__image ${index === currentIndex ? 'active' : ''}`}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="natural-rooms">
      <LeafBackground>
        {[...Array(30)].map((_, index) => (
          <Leaf
          key={index}
            initial={{ x: Math.random() * window.innerWidth, y: window.innerHeight + 20 }}
            animate={{
              y: [-20, window.innerHeight + 20],
              x: [Math.random() * 50 - 25, Math.random() * 50 - 25],
              rotate: 360,
              transition: {
                duration: Math.random() * 5 + 18,
                repeat: Infinity,
                ease: "linear",
              },
            }}
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              backgroundImage: `url(${hoja})`,
            }}
          />
        ))}
      </LeafBackground>
      <h1 className="natural-rooms__title">Nuestras Habitaciones</h1>
      <p className = "natural-rooms__text" >En Anthea Loft, nuestras habitaciones están diseñadas para ofrecerte una experiencia de tranquilidad y elegancia. Ya sea que elijas una suite estándar o una suite, disfrutarás de un espacio donde el diseño moderno y el confort se unen para crear el refugio perfecto.</p>
      <div className="natural-rooms__container">
        {rooms.map((room) => (
          <div key={room.type} className="room-card">
            <ImageCarousel images={room.images} />
            <div className="room-card__content">
              <h2 className="room-card__title">{room.title}</h2>
              <p className="room-card__description">{room.description}</p>
              <div className="room-card__icons">
                {room.icons.map((icon, index) => (
                  <div key={index} className="room-card__icon" title={icon.label}>
                    {icon.icon}
                  </div>
                ))}
              </div>
              <button className="room-card__button" onClick={() => cambiarRuta(room)}>
                Ver más <ArrowForward />
              </button>
            </div>
          </div>
        ))}
      </div>
      {activeRoom && (
        <div className="modal" onClick={() => setActiveRoom(null)}>
          <div className="modal__content" onClick={(e) => e.stopPropagation()}>
            <button className="modal__close" onClick={() => setActiveRoom(null)}>✖</button>
            <h2 className="modal__title">{activeRoom.title}</h2>
            <ImageCarousel images={activeRoom.images} />
            <p className="modal__description">
              {activeRoom.type === "suite"
                ? "Nuestra suite de lujo ofrece una experiencia inigualable con amplios espacios, decoración de lujo y comodidades premium. Disfruta de un oasis de tranquilidad mientras disfrutas de jacuzzi."
                : "La habitación estándar proporciona un refugio acogedor. Perfecta para relajarse después de un día de aventuras, con todas las comodidades necesarias para una estancia placentera."}
            </p>
            <ul className="modal__features">
              <li>Decoración de Lujo</li>
              <li>Baño con implementos necesarios</li>
              <li>{activeRoom.type === "suite" ? "Jacuzzi" : "Tina de Baño"}</li>
              <li>Wi-Fi de alta velocidad</li>
              <li>Televisión de pantalla plana</li>
            </ul>
            <div className="modal__icons">
              {activeRoom.icons.map((icon, index) => (
                <div key={index} className="modal__icon" title={icon.label}>
                  {icon.icon}
                  <span>{icon.label}</span>
                </div>
              ))}
            </div>
            <button className="modal__button">Reservar Ahora</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NaturalRooms;