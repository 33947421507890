import { useCallback, useEffect, useState } from 'react'; 
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import NavBar from '../components/Navbar';
import Footer from '../components/footer';
import useScrollDirection from '../hooks/useScrollDirection';

import 'simplebar-react/dist/simplebar.min.css';

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
});

export const Layout = (props) => {
  const { children } = props;
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const scrollDirection = useScrollDirection();
  const location = useLocation();
  const [previousPathname, setPreviousPathname] = useState(location.pathname);
  const [isMobile, setIsMobile] = useState(false); // Estado para detectar modo teléfono

  // Chequeo para detectar modo teléfono
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Ajusta el tamaño según tu diseño
  };

  // Efecto para añadir el listener de resize
  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Manejar la visibilidad del navbar según la dirección del scroll
  useEffect(() => {
    if (!isMobile) { // Solo oculta el navbar en modo computadora
      if (scrollDirection === 'down') {
        setIsNavbarVisible(false);
      } else if (scrollDirection === 'up') {
        setIsNavbarVisible(true);
      }
    }
  }, [scrollDirection, isMobile]);

  // Manejar cambio de pathname para restablecer la visibilidad del navbar y scroll
  const handlePathnameChange = useCallback(() => {
    setIsNavbarVisible(true); // Siempre mostrar navbar en cambio de vista
    window.scrollTo(0, 0); // Desplazar a la parte superior
  }, []);

  useEffect(() => {
    if (previousPathname !== location.pathname) {
      handlePathnameChange();
      setPreviousPathname(location.pathname); // Actualiza el pathname anterior
    }
  }, [location.pathname, handlePathnameChange, previousPathname]);

  return (
    <>
      {isNavbarVisible && <NavBar />}
      <LayoutRoot>
        <LayoutContainer>
          {children}
        </LayoutContainer>
      </LayoutRoot>
      <Footer />
    </>
  );
};