import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeView from './views/login';
import { Layout } from './layouts/layout';
import Loading from './components/DotSpinner';

const Menu = React.lazy(() => import('./views/bar'));
const ContactViews = React.lazy(() => import('./views/contacto'));
const Promociones = React.lazy(() => import('./views/promociones'));
const OcasionesEspeciales = React.lazy(() => import('./views/ocasiones'));
const HabitacionesVista = React.lazy(() => import('./components/habitacion'));

function App() {
  return (
    <BrowserRouter>
      <div className='background-container'>
     
        <Layout>
          <Suspense fallback={<div><Loading /></div>}>
            <Routes>
              <Route path='/' element={<HomeView />} />
              <Route path='/suites-motel-cuenca' element={<HomeView />} />
              <Route path='/suites-motel-cuenca-habitaciones' element={<HabitacionesVista />} />
              <Route path='/bar-restaurante-motel-cuenca' element={<Menu />} />
              <Route path='/sexshop-motel-cuenca' element={<Promociones />} />
              <Route path='/contacto-motel-cuenca' element={<ContactViews />} />
              <Route path='/decoraciones-romanticas-motel-cuenca' element={<OcasionesEspeciales />} />
            </Routes>
          </Suspense>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

export default App;

