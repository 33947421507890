export default function Experiencias() {
  return (
    <section id="tw-facts" className="tw-facts-nosotros bg-overlay">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="titulo-parallax">Experiencias</h1>
            <p className="texto-parallax">Donde la pasión no tiene límites</p>
          </div>
        </div>
        <div className="icon-grid">
          <div className="icon-row">
            <div className="icon-item">
              <svg width="85" height="96" viewBox="0 0 85 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.5 0C27.6875 0 30.5 2.8125 30.5 6V12H54.5V6C54.5 2.8125 57.125 0 60.5 0C63.6875 0 66.5 2.8125 66.5 6V12H75.5C80.375 12 84.5 16.125 84.5 21V30H0.5V21C0.5 16.125 4.4375 12 9.5 12H18.5V6C18.5 2.8125 21.125 0 24.5 0ZM0.5 36H84.5V87C84.5 92.0625 80.375 96 75.5 96H9.5C4.4375 96 0.5 92.0625 0.5 87V36ZM12.5 51V57C12.5 58.6875 13.8125 60 15.5 60H21.5C23 60 24.5 58.6875 24.5 57V51C24.5 49.5 23 48 21.5 48H15.5C13.8125 48 12.5 49.5 12.5 51ZM36.5 51V57C36.5 58.6875 37.8125 60 39.5 60H45.5C47 60 48.5 58.6875 48.5 57V51C48.5 49.5 47 48 45.5 48H39.5C37.8125 48 36.5 49.5 36.5 51ZM63.5 48C61.8125 48 60.5 49.5 60.5 51V57C60.5 58.6875 61.8125 60 63.5 60H69.5C71 60 72.5 58.6875 72.5 57V51C72.5 49.5 71 48 69.5 48H63.5ZM12.5 75V81C12.5 82.6875 13.8125 84 15.5 84H21.5C23 84 24.5 82.6875 24.5 81V75C24.5 73.5 23 72 21.5 72H15.5C13.8125 72 12.5 73.5 12.5 75ZM39.5 72C37.8125 72 36.5 73.5 36.5 75V81C36.5 82.6875 37.8125 84 39.5 84H45.5C47 84 48.5 82.6875 48.5 81V75C48.5 73.5 47 72 45.5 72H39.5ZM60.5 75V81C60.5 82.6875 61.8125 84 63.5 84H69.5C71 84 72.5 82.6875 72.5 81V75C72.5 73.5 71 72 69.5 72H63.5C61.8125 72 60.5 73.5 60.5 75Z" fill="white" />
              </svg>
              <p className="text-experiences">Atención 24/7</p>
            </div>
            <div className="icon-item">
              <svg width="85" height="96" viewBox="0 0 85 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.5 27V36H57.5V27C57.5 18.75 50.75 12 42.5 12C34.0625 12 27.5 18.75 27.5 27ZM15.5 36V27C15.5 12.1875 27.5 0 42.5 0C57.3125 0 69.5 12.1875 69.5 27V36H72.5C79.0625 36 84.5 41.4375 84.5 48V84C84.5 90.75 79.0625 96 72.5 96H12.5C5.75 96 0.5 90.75 0.5 84V48C0.5 41.4375 5.75 36 12.5 36H15.5Z" fill="white" />
              </svg>
              <p className="text-experiences">Seguridad</p>
            </div>
            <div className="icon-item">
            <svg width="85" height="96" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4.6138 8.54479L4.1875 10.25H2C1.58579 10.25 1.25 10.5858 1.25 11C1.25 11.4142 1.58579 11.75 2 11.75H22C22.4142 11.75 22.75 11.4142 22.75 11C22.75 10.5858 22.4142 10.25 22 10.25H19.8125L19.3862 8.54479C18.8405 6.36211 18.5677 5.27077 17.7539 4.63538C16.9401 4 15.8152 4 13.5653 4H10.4347C8.1848 4 7.05988 4 6.24609 4.63538C5.43231 5.27077 5.15947 6.36211 4.6138 8.54479ZM6.5 21C8.12316 21 9.48826 19.8951 9.88417 18.3963L10.9938 17.8415C11.6272 17.5248 12.3728 17.5248 13.0062 17.8415L14.1158 18.3963C14.5117 19.8951 15.8768 21 17.5 21C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14C15.8399 14 14.4498 15.1558 14.0903 16.7065L13.6771 16.4999C12.6213 15.972 11.3787 15.972 10.3229 16.4999L9.90967 16.7065C9.55023 15.1558 8.16009 14 6.5 14C4.567 14 3 15.567 3 17.5C3 19.433 4.567 21 6.5 21Z" fill="white"/>
</svg>

              <p className="text-experiences">Privacidad</p>
            </div>
          
            <div className="icon-item">
            <svg width="85" height="96" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10 6H14C16.8284 6 18.2426 6 19.1213 6.87868C20 7.75736 20 9.17157 20 12V13.0557C20 15.4614 20 16.6642 19.4026 17.6308C18.8052 18.5974 17.7294 19.1353 15.5777 20.2111C13.8221 21.089 12.9443 21.5279 12 21.5279C11.0557 21.5279 10.1779 21.089 8.42229 20.2111C6.27063 19.1353 5.19479 18.5974 4.5974 17.6308C4 16.6642 4 15.4614 4 13.0557V12C4 9.17157 4 7.75736 4.87868 6.87868C5.75736 6 7.17157 6 10 6ZM12 10C11.7159 10 11.5259 10.3408 11.1459 11.0225L11.0476 11.1989C10.9397 11.3926 10.8857 11.4894 10.8015 11.5533C10.7173 11.6172 10.6125 11.641 10.4028 11.6884L10.2119 11.7316C9.47396 11.8986 9.10501 11.982 9.01723 12.2643C8.92945 12.5466 9.18097 12.8407 9.68403 13.429L9.81418 13.5812C9.95713 13.7483 10.0286 13.8319 10.0608 13.9353C10.0929 14.0387 10.0821 14.1502 10.0605 14.3733L10.0408 14.5763C9.96476 15.3612 9.92674 15.7536 10.1565 15.9281C10.3864 16.1025 10.7318 15.9435 11.4227 15.6254L11.6014 15.5431C11.7978 15.4527 11.8959 15.4075 12 15.4075C12.1041 15.4075 12.2022 15.4527 12.3986 15.5431L12.5773 15.6254C13.2682 15.9435 13.6136 16.1025 13.8435 15.9281C14.0733 15.7536 14.0352 15.3612 13.9592 14.5763L13.9395 14.3733C13.9179 14.1502 13.9071 14.0387 13.9392 13.9353C13.9714 13.8319 14.0429 13.7483 14.1858 13.5812L14.316 13.429C14.819 12.8407 15.0706 12.5466 14.9828 12.2643C14.895 11.982 14.526 11.8986 13.7881 11.7316L13.5972 11.6884C13.3875 11.641 13.2827 11.6172 13.1985 11.5533C13.1143 11.4894 13.0603 11.3926 12.9524 11.1989L12.8541 11.0225C12.4741 10.3408 12.2841 10 12 10Z" fill="white"/>
<path d="M10.9998 2H12.9998C14.8855 2 15.8283 2 16.4141 2.58579C16.828 2.99968 16.9494 3.59181 16.985 4.56879C16.1642 4.49989 15.1938 4.49994 14.0986 4.5H9.90111C8.80589 4.49994 7.8355 4.49989 7.01465 4.56879C7.05029 3.59181 7.17174 2.99968 7.58563 2.58579C8.17142 2 9.11423 2 10.9998 2Z" fill="white"/>
</svg>

              <p className="text-experiences">Exclusivo</p>
            </div>
          </div>
          <div className="icon-row">
            <div className="icon-item">
            <svg width="85" height="84" viewBox="0 0 85 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 0H72.5C79.0625 0 84.5 5.4375 84.5 12V72C84.5 78.75 79.0625 84 72.5 84H12.5C5.75 84 0.5 78.75 0.5 72V12C0.5 5.4375 5.75 0 12.5 0ZM36.5 42V30H45.5C48.6875 30 51.5 32.8125 51.5 36C51.5 39.375 48.6875 42 45.5 42H36.5ZM45.5 54C55.4375 54 63.5 45.9375 63.5 36C63.5 26.0625 55.4375 18 45.5 18H32C27.6875 18 24.5 21.375 24.5 25.5V48V60C24.5 63.375 27.125 66 30.5 66C33.6875 66 36.5 63.375 36.5 60V54H45.5Z" fill="white"/>
</svg>

              <p className="text-experiences">Parqueadero</p>
            </div>
            <div className="icon-item">
            <svg width="121" height="96" viewBox="0 0 121 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M96.5 84C99.6875 84 102.5 86.8125 102.5 90C102.5 93.375 99.6875 96 96.3125 96H24.3125C21.125 96 18.5 93 18.5 90C18.5 86.8125 21.125 84 24.3125 84H96.5ZM111.5 0C116.375 0 120.5 4.125 120.5 9V69C120.5 74.0625 116.375 78 111.5 78H9.5C4.4375 78 0.5 74.0625 0.5 69V9C0.5 4.125 4.4375 0 9.5 0H111.5ZM108.5 66V12H12.5V66H108.5Z" fill="white"/>
</svg>

              <p className="text-experiences">TV Satélital</p>
            </div>
            <div className="icon-item">
            <svg width="85" height="96" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.2991 3H4.70095C3.20008 3 2.43759 4.79409 3.48381 5.86382L6.23508 9H17.7649L20.5162 5.86382C21.5624 4.79409 20.7999 3 19.2991 3Z" fill="white"/>
<path d="M16.449 10.5H7.55099L11.2498 14.7162V20.2499H7.75586C7.34165 20.2499 7.00586 20.5856 7.00586 20.9999C7.00586 21.4141 7.34165 21.7499 7.75586 21.7499H16.2437C16.6579 21.7499 16.9937 21.4141 16.9937 20.9999C16.9937 20.5856 16.6579 20.2499 16.2437 20.2499H12.7498V14.7168L16.449 10.5Z" fill="white"/>
</svg>

              <p className="text-experiences">Comidas</p>
            </div>
            <div className="icon-item">
              <svg width="121" height="84" viewBox="0 0 121 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 0C9.6875 0 12.5 2.8125 12.5 6V54H54.5V24C54.5 20.8125 57.125 18 60.5 18H102.5C112.438 18 120.5 26.0625 120.5 36V78C120.5 81.375 117.688 84 114.5 84C111.125 84 108.5 81.375 108.5 78V72H66.5H60.5H12.5V78C12.5 81.375 9.6875 84 6.5 84C3.125 84 0.5 81.375 0.5 78V6C0.5 2.8125 3.125 0 6.5 0ZM33.5 18C38.75 18 43.625 21 46.4375 25.5C49.0625 30.1875 49.0625 36 46.4375 40.5C43.625 45.1875 38.75 48 33.5 48C28.0625 48 23.1875 45.1875 20.375 40.5C17.75 36 17.75 30.1875 20.375 25.5C23.1875 21 28.0625 18 33.5 18Z" fill="white" />
              </svg>
              <p className="text-experiences">Estancias</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
