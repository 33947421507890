import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ImageWithOverlay({ imageSrc, name, path }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <div className="image-overlay-container" onClick={handleClick} style={{ cursor: 'pointer' }}>
      <img src={imageSrc} alt="Overlay Background" className="background-image" />
      <div className="overlay-content">
        <h1>{name}</h1>
        <button
          onClick={(e) => {
            e.stopPropagation(); 
            handleClick();
          }}
          className="learn-more-button" 
          style={{ background: 'none', border: 'none', color: 'white', textDecoration: 'underline', cursor: 'pointer', width:'100%', fontSize:'16px', marginTop:'10px' }}
        >
          Conoce más
        </button>
      </div>
    </div>
  );
}
