import { useState, useEffect } from 'react';

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [lastScrollY, setLastScrollY] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;

      // Umbral para detectar un scroll significativo
      const threshold = 10;

      // Comprobar si hay un cambio suficiente en el scroll para actualizar la dirección
      if (Math.abs(currentScrollY - lastScrollY) >= threshold) {
        if (currentScrollY > lastScrollY) {
          setScrollDirection('down');
        } else if (currentScrollY < lastScrollY) {
          setScrollDirection('up');
        }
        setLastScrollY(currentScrollY);
      }
    };

    // Añadir el evento de scroll
    window.addEventListener('scroll', handleScroll, { passive: true });

    // Limpiar el evento de scroll al desmontar el componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return scrollDirection;
};

export default useScrollDirection;











// import { useState, useEffect } from 'react';

// const useScrollDirection = () => {
//   const [scrollDirection, setScrollDirection] = useState(null);
//   const [lastScrollY, setLastScrollY] = useState(window.pageYOffset);

//   useEffect(() => {
//     const handleScroll = () => {
//       const currentScrollY = window.pageYOffset;

//       // Solo actualizamos la dirección si ha cambiado
//       if (currentScrollY > lastScrollY) {
//         setScrollDirection('down');
//       } else if (currentScrollY < lastScrollY) {
//         setScrollDirection('up');
//       }

//       // Actualizamos el valor de lastScrollY
//       setLastScrollY(currentScrollY);
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, [lastScrollY]);

//   return scrollDirection;
// };

// export default useScrollDirection;
