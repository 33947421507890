import React from 'react';


export default function Loading() {
  return (
    <div className="loading-overlay">
      <div className="loading-container">
        <svg
          className="logo"
          width="80"
          height="80"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M50 0L93.3013 25V75L50 100L6.69873 75V25L50 0Z"
            fill="#2f5626"
          />
          <path
            d="M50 20L79.2487 35V65L50 80L20.7513 65V35L50 20Z"
            fill="white"
          />
        </svg>
        {/* <div className="spinner"></div> */}
        <p className="welcome-text">
          Bienvenido a Hotel Anthea
        </p>
        <p className="loading-text">Cargando experiencias lujosas...</p>
      </div>
    </div>
  );
}


