import Cardd from "../cardd";
import primera from '../../assets/coctel.webp';  
import segunda from '../../assets/habitacion.webp'; 
import tercera from '../../assets/descuento.webp'; 

export default function Promociones () {
    return (
        <>
            <section id="tw-facts" className="tw-facts-promociones bg-overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="titulo-parallax">Promociones</h1>

            </div>
          </div>
        </div>
      </section>
      <div className="gallery">
      <div className="card-container">
        <Cardd
          imageSrc={primera}
          title="2x1"
          description="En cocteles"
        />
        <Cardd
          imageSrc={segunda}
          title="Gratis"
          description="Desayuno en tus amanecidas"
        />
        <Cardd
          imageSrc={tercera}
          title="10% Descuento"
          description="En tu segunda decoración"
        />
      </div>
    </div>
        </>
    )
}