import React, { useState, useEffect } from 'react';
import HeaderMenuDefault from './HeaderMenuDefault';
import { useLocation } from 'react-router-dom';
import LogoFinal from '../assets/logo.png';
import { useMediaQuery } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import NavItem from './NavMenu';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import CelebrationIcon from '@mui/icons-material/Celebration';
import StoreIcon from '@mui/icons-material/Store';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export default function NavBar() {
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [navbarClass, setNavbarClass] = useState('transparent');

    // Maneja el scroll
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarClass('change-bg');
            } else {
                setNavbarClass('transparent');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleDrawer = (open) => (event) => {
        setOpen(open);
    };

    const handleNavItemClick = () => {
        setOpen(false);
    };

    const list = (anchor) => (
        <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }} role="presentation">
            <List>
                {menu_data.map((item) => {
                    if (item.type === "image" && !lgUp) {
                        return null;
                    } else {
                        const active = item.path ? (location.pathname === item.path) : false;
                        return (
                            <NavItem
                                key={item.name}
                                text={item.name}
                                path={item.path}
                                estatus={active}
                                child={item.child}
                                childrens={item.childrens}
                                onClick={handleNavItemClick}
                                icon={item.icon}
                            />
                        );
                    }
                })}
            </List>
        </Box>
    );

    if (lgUp) {
        return (
            <div style={{ maxWidth: '100%', overflowX: 'hidden' }}>
                <div className={`header ${navbarClass}`} >
                    <div className="header-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="middle-nav-items">
                            {menu_data.map((item) => {
                                if (item.type === "image") {
                                    return (
                                        <img key="logo" src={item.src} alt={item.alt} height={item.height} style={{ display: 'inline-block', margin: '0 10px' }} />
                                    );
                                } else {
                                    const active = item.path ? (location.pathname === item.path) : false;
                                    return (
                                        <HeaderMenuDefault
                                            key={item.name}
                                            className="design-component-instance-node"
                                            text={item.name}
                                            path={item.path}
                                            estatus={active}
                                            child={item.child}
                                            childrens={item.childrens}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Box sx={{ flexGrow: 1, maxWidth: '100%', overflowX: 'hidden' }}>
                <AppBar position="static" sx={{ maxWidth: '100%', overflowX: 'hidden', background:'#344e1f' }}>
                    <Toolbar variant="dense" sx={{ backgroundColor: navbarClass === 'change-bg' ? "#355d2a" : "transparent", padding: 2, justifyContent: 'space-between' }}>
                        <img src={LogoFinal} alt="Logo" style={{ height: "50px", width: "auto" }} />
                        <IconButton edge="end" onClick={toggleDrawer(true)} style={{ color: "#fff" }} aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer
                    anchor={'left'}
                    open={open}
                    onClose={toggleDrawer(false)}
                    sx={{ maxWidth: '100%', overflowX: 'hidden' }}
                >
                    {list('left')}
                </Drawer>
            </Box>
        );
    }
}

const menu_data = [
    {
        name: "Inicio",
        path: '/',
        child: false,
        icon: <HomeRoundedIcon />,
        childrens: [],
    },
    {
        name: "Suites",
        path: '/suites-motel-cuenca-habitaciones',
        child: false,
        icon: <SingleBedIcon />,
        childrens: []
    },
    {
        name: "Bar&Restaurant",
        path: '/bar-restaurante-motel-cuenca',
        child: false,
        icon: <DinnerDiningIcon />,
        childrens: []
    },
    {
        type: "image",
        src: LogoFinal,
        alt: "Logo",
        height: 90,
    },
    {
        name: "Especiales",
        path: '/decoraciones-romanticas-motel-cuenca',
        child: false,
        icon: <CelebrationIcon />,
        childrens: []
    },
    {
        name: "Sexshop",
        path: '/sexshop-motel-cuenca',
        child: false,
        icon: <StoreIcon />,
        childrens: []
    },
    {
        name: "Contactos",
        path: '/contacto-motel-cuenca',
        child: false,
        icon: <LocalPhoneIcon />,
        childrens: []
    },
];
